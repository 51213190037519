document.addEventListener("turbolinks:load", function() {
  $('div[data-system-id="road-name"] input')
    .easyAutocomplete({
      url: function(phrase) {
        return "/addresses/search.json?type=roads&q=" + phrase;
      },
      getValue: function(element) {
        return $(element).prop("name");
      },
      theme: 'bootstrap',
      list: {
        maxNumberOfElements: 15,
        onSelectItemEvent: function() {
          // this is not the best way to find our field but since it is presumably only one per page it should work
          let input = $('div[data-system-id="road-name"] input');
          let data = input.getSelectedItemData();
          //console.debug('choose: Subdivision:', data.subdivision, 'Unit:', data.unit, 'Region:', data.region);
          // populate these possibly existing hidden fields if present
          $('div[data-system-id="subdivision"] input').val(data.subdivision);
          $('div[data-system-id="unit"] select').val(data.unit);
          $('div[data-system-id="region"] select').val(data.region);
          $('div[data-system-id="length"] input').val(data.length);
          $('div[data-system-id="category"] input').val(data.category);
          $('div[data-system-id="width"] input').val(data.width);
          $('div[data-system-id="maintained"] input').val(data.maintained);
        }
      },
    })
    .on('change', function() {
      // if what they entered is not in the list, then we need to clear the fields
      let items = $('div[data-system-id="road-name"] input').getItems();
      let value = $('div[data-system-id="road-name"] input').val();
      //console.debug('change', value);
      let found = false;
      if (value != '') {
        for (let index = 0; index < items.length; index++) {
          if (items[index].name == value) {
            found = true;
            break;
          }
        }
      }

      // console.debug('found? ', found);
      if (!found) {
        $('div[data-system-id="road-name"] input').val('');
        $('div[data-system-id="subdivision"] input').val('');
        $('div[data-system-id="unit"] input').val('');
        $('div[data-system-id="region"] input').val('');
        $('div[data-system-id="length"] input').val('');
        $('div[data-system-id="category"] input').val('');
        $('div[data-system-id="width"] input').val('');
        $('div[data-system-id="maintained"] input').val('');
      }
    });
});
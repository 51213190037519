$(document).on('change', 'div[data-system-id="ak-business-license"] input', function(e) {
  // console.debug('e is ', e.currentTarget, 'and this is ', this);
  let value = $(e.currentTarget).val();

  function setValues(data) {
    $('div[data-system-id="entity-name"] input').val(data.business_name);
    $('div[data-system-id="mailing-address-line1"] input').val(data.mailing_line1);
    $('div[data-system-id="mailing-address-line2"] input').val(data.mailing_line2);
    $('div[data-system-id="mailing-city"] input').val(data.mailing_city);
    $('div[data-system-id="mailing-state"] input').val(data.mailing_state);
    $('div[data-system-id="mailing-zip"] input').val(data.mailing_zip);
  }

  function nullData() {
    return { business_name: '', mailing_line1: '', mailing_line2: '', mailing_city: '', mailing_state: '', mailing_zip: '' };
  }

  if (value == null || value == '') {
    setValues(nullData());
    return;
  }

  $.get('/addresses/search?type=business_license&q=' + value, function (data) {
    if (data == null) {
      data = nullData();
    }

    setValues(data);
  });
});

// this is for when questions are being defined for a questionnaire
// when a question-type changes to selection or checkboxes, enable the options field unless the questions-type itself is readonly
$(document).on('change', 'select.question-types', function (e) {
  if (!$(e.target).prop('readonly')) {
    var questionType = $(e.target).val();
    // options
    if (questionType == 'Selection' || questionType == 'Checkboxes') {
      $('.question-options')[0].removeAttribute('readonly');
    } else {
      $('.question-options').prop('readonly', true);
    }
    // exclusivity
    if (questionType == 'Checkbox' || questionType == 'Checkboxes') {
      $('.question-exclusivity-group')[0].removeAttribute('readonly');
    } else {
      $('.question-exclusivity-group').prop('readonly', true);
    }
  }
});

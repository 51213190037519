function toggleTransitionRoles(e) {
  var me = $(e.target);
  var checked = me.prop('checked');
  $(e.target).closest('tr').find('td.transitions input').each(function(_i, obj) {
    $(obj).prop('checked', checked).trigger('change');
  });
}

console.debug('wiring up toggleTransitionRoles');
$(document).on('change', 'input.check-transitions-too', function (e) {
  toggleTransitionRoles(e);
});

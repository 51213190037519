function toggleExclusivityQuestions(e) {
  var me = $(e.target);
  console.debug('invoked toggleExclusivityQuestions', e);

  if (me.prop('checked')) {
    // uncheck the others in the exclusivity group
    $('[data-exclusivity-group="' + me.data('exclusivity-group') + '"]').each(function(_i, obj) {
      if (me[0] !== obj && $(obj).prop('checked')) {
        $(obj).prop('checked', false).trigger('change');
      }
    });
  }
}

console.debug('wiring up toggleExclusivityQuestions');
$(document).on('change', '.question input[data-exclusivity-group]', function (e) {
  toggleExclusivityQuestions(e);
});

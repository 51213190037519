// extend JQuery to simulate clicks on other than IE
// see http://stackoverflow.com/questions/20928915/jquery-triggerclick-not-working
jQuery.fn.simulateClick = function() {
  return this.each(function() {
      if('createEvent' in document) {
          var doc = this.ownerDocument,
              evt = doc.createEvent('MouseEvents');
          evt.initMouseEvent('click', true, true, doc.defaultView, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
          this.dispatchEvent(evt);
      } else {
          this.click(); // IE Boss!
      }
  });
}

// make row clicks click the show link
$(document).on('click', 'table.row-click-show tr', function(e) {
  // See http://stackoverflow.com/questions/20928915/jquery-triggerclick-not-working
  // $(this).find('a.show-link').trigger('click');
  // works, but not on iphone
  // $(this).find('a.show-link')[0].click();
  if (e.target.nodeName != 'A' && e.target.nodeName != 'INPUT') {
    // dont do this on a cell with a checkbox since it is too easy to miss the checkbox
    if ($(e.target).find('input[type=checkbox]').length == 0) {
      $(this).find('a.show-link').simulateClick('click');
    }
  }
});

// this is when answers are being entered for a survey
// when an answer changes, update any conditional questions on the current page that rely upon it 
function toggleConditionalQuestions() {
  // console.debug('toggleConditionalQuestions firing');
  // get conditional questions
  // if they depend on an answer in the dom, then check it
  $('[data-applicable-when-id]').each(function(_i, dependentQuestion) {
    // if this dependent question is locked, then dont toggle it
    if ($(dependentQuestion).hasClass('locked')) {
      return;
    }

    var dependentInputs = $(dependentQuestion).find('input');
    if (dependentInputs.length == 0) {
      // maybe it is a select instead?
      dependentInputs = $(dependentQuestion).find('select');
    }
    if (dependentInputs.length == 0) {
      // maybe it is a textarea instead?
      dependentInputs = $(dependentQuestion).find('textarea');
    }

    var whensId = dependentQuestion.dataset.applicableWhenId;
    var applicableWhenValueMatches = dependentQuestion.dataset.applicableWhenValue.split('|');
    var whensInputs = $('[name^="fill[' + whensId + ']"]');
    var whensValues = [];

    if (whensInputs.length == 0) {
      // cannot find the value that this is dependent upon so it must be on a prior page
      // which means the server set the applicability of this question and it wont change
      // interactively here, so leave it be.
      return;

    } else if (whensInputs.length == 1) {
      // add just the one value
      whensValues.push(whensInputs.val());
    } else if (whensInputs.length > 1) {
      // it is an array of checkboxes or radio buttons
      // if none checked then use the value of the hidden checkbox
      // else add all the checked ones
      var whensDefault = null;
      for (var i = 0; i <= whensInputs.length; i++) {
        var value = $(whensInputs[i]).val();
        if ($(whensInputs[i]).attr('type') == 'hidden') {
          // rails has a default hidden checkbox value as an input hidden
          whensDefault = value;
        } else {
          // must be an actual checkbox or radio button
          if ($(whensInputs[i]).prop('checked')) {
            whensValues.push(value);
          }
        }
      }
      if (whensValues.length == 0 && whensDefault !== null) {
        whensValues.push(whensDefault);
      }
    }

    var met = false;
    // determine if any conditions are met
    $.each(whensValues, function (_i, element) {
      met = met || (applicableWhenValueMatches.indexOf(element) != -1);
    });

    // console.debug('checking', dependentQuestion.dataset.questionId, 'enabled when', whensId, 'which is', whensValues, 'matches', applicableWhenValueMatches, 'setting', dependentInputs);

    if (met) {
      console.debug('  enabling');
      dependentInputs.prop('readonly', false);
      dependentInputs.prop('disabled', false);
      dependentInputs.removeAttr('readonly');
      dependentInputs.removeAttr('disabled');

      // show if hidden
      $(dependentQuestion).removeClass('d-none');
    } else {
      // console.debug('  disabling');
      dependentInputs.prop('readonly', true);
      dependentInputs.prop('disabled', true);

      // hide if hideable
      if ($(dependentQuestion).data('hide-when-not-applicable')) {
        $(dependentQuestion).addClass('d-none');
      }
    }
  });
}

$(document).on('change', '.question input', function () {
  toggleConditionalQuestions();
});

$(document).on('change', '.question select', function () {
  toggleConditionalQuestions();
});

// append question_id and index of attachment to remove
$(document).on('click', 'form.fill-form [data-action="remove-file"]', function (e) {
  var rf = $('input[name="remove_files"]');
  var files = rf.val();
  files = (files || '') + $(e.target).data('key') + ',';
  rf.val(files);
  $(e.target).closest('.file').remove();
});

document.addEventListener("turbolinks:load", function() {
  $address_field = $('div[data-system-id="physical-address"]')
  $input = $address_field.find("input")

  var options = {
    url: function(phrase) {
      return "/addresses/search.json?type=physical&q=" + phrase;
    },
    getValue: "name"
  };

  $input.easyAutocomplete(options);
});

document.addEventListener("turbolinks:load", function() {
  $('div[data-system-id="jurisdiction-address"] input')
    .easyAutocomplete({
      url: function(phrase) {
        return "/addresses/search.json?type=jurisdiction&q=" + phrase;
      },
      getValue: "name",
      theme: 'bootstrap',
      list: {
        onChooseEvent: function() {
          // this is not the best way to find our field but since it is presumably only one per page it should work
          let input = $('div[data-system-id="jurisdiction-address"] input');
          let data = input.getSelectedItemData();
          // console.debug('choose: jurisdiction:', data.jurisdictionId, 'inCity:', data.inCity, 'city:', data.city);
          // populate these possibly existing hidden fields if present
          $('div[data-system-id="jurisdiction-id"] input').val(data.jurisdictionId);
          $('div[data-system-id="jurisdiction-city"] input').val(data.city);
          $('div[data-system-id="jurisdiction-incity"] input').val(data.inCity).trigger('change');
          $('div[data-system-id="jurisdiction-by-mail-precinct"] input').val(data.byMailPrecinct).trigger('change');
          if (data.inCity == 'N') {
            // unset the city ballot
            $('div[data-system-id="city-ballot"] input').prop('checked', false).trigger('change');
          }
        }
      },
    })
    .on('change', function() {
      // if what they entered is not in the list, then we need to clear the fields
      let items = $('div[data-system-id="jurisdiction-address"] input').getItems();
      let value = $('div[data-system-id="jurisdiction-address"] input').val();
      //console.debug('change', value);
      let found = false;
      if (value != '') {
        for (let index = 0; index < items.length; index++) {
          if (items[index].name == value) {
            found = true;
            break;
          }
        }
      }

      // console.debug('found? ', found);
      if (!found) {
        $('div[data-system-id="jurisdiction-address"] input').val('');
        $('div[data-system-id="jurisdiction-id"] input').val('');
        $('div[data-system-id="jurisdiction-city"] input').val('');
        $('div[data-system-id="jurisdiction-incity"] input').val('N').trigger('change');
        $('div[data-system-id="jurisdiction-by-mail-precinct"] input').val('N').trigger('change');
        // unset the city ballot
        $('div[data-system-id="city-ballot"] input').prop('checked', false).trigger('change');
      }
    });
});

// Address lookup for emergency notifications
document.addEventListener("turbolinks:load", function() {
  $('div[data-system-id="street"] input')
    .easyAutocomplete({
      url: function(phrase) {
        return "/addresses/search.json?type=emergency&q=" + phrase;
      },
      getValue: "name",
      theme: 'bootstrap',
      list: {
        onChooseEvent: function() {
          // this is not the best way to find our field but since it is presumably only one per page it should work
          let input = $('div[data-system-id="street"] input');
          let data = input.getSelectedItemData();
          // console.debug('choose: emergency:', data.emergencyId, 'inCity:', data.inCity, 'city:', data.city);
          // populate these possibly existing hidden fields if present
          $('div[data-system-id="zip"] input').val(data.zip);
          $('div[data-system-id="community"] input').val(data.community);
          $('div[data-system-id="city"] input').val(data.city);
        }
      },
    })
    .on('change', function() {
      // if what they entered is not in the list, then we need to clear the fields
      let items = $('div[data-system-id="street"] input').getItems();
      let value = $('div[data-system-id="street"] input').val();
      //console.debug('change', value);
      let found = false;
      if (value != '') {
        for (let index = 0; index < items.length; index++) {
          if (items[index].name == value) {
            found = true;
            break;
          }
        }
      }

      // console.debug('found? ', found);
      if (!found) {
        $('div[data-system-id="street"] input').val('');
        $('div[data-system-id="zip"] input').val('');
        $('div[data-system-id="city"] input').val('');
        $('div[data-system-id="community"] input').val('');
      }
    });
});
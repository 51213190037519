// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("easy-autocomplete");
require('packs/addresses')
require('packs/roads')
require("tempusdominus-bootstrap-4")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
 
import "bootstrap";
import "../stylesheets/application";

//https://code-and-cookies.com/2020/01/new-rails-6-project/
import "@fortawesome/fontawesome-free/js/all";

//https://github.com/kollegorna/cocoon-vanilla-js
import 'cocoon';

import 'select2';

require('packs/business_license_lookup');
require('packs/comments');
require('packs/history');
require('packs/reviews');
require('packs/row_click_show');
require('packs/submit_on_change');
require('packs/surveys');
require('packs/toggle_conditional_questions');
require('packs/toggle_exclusivity_questions');
require('packs/toggle_question_criteria');
require('packs/users');
require('packs/questions_status_access');
require('packs/copy_fields');
require('packs/toggle_role');

require('packs/jquery.are-you-sure.js');  // from repo at KPB-US:/jquery.AreYouSure

// popover for question help
document.addEventListener("turbolinks:load", () => {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
  })
});


$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
  icons: {
      time: 'far fa-clock',
      date: 'fa fa-calendar',
      up: 'fas fa-arrow-up',
      down: 'fas fa-arrow-down',
      previous: 'fas fa-chevron-left',
      next: 'fas fa-chevron-right',
      today: 'far fa-calendar-check-o',
      clear: 'fas fa-trash',
      close: 'far fa-times'
  } 
});

// date picker initializations
document.addEventListener("turbolinks:load", () => {
  $(function () {
    $('.date_time_picker > .input-group.date').datetimepicker({
      sideBySide: true,
      format: "YYYY-MM-DD HH:mm"
    });

    $('.date_picker > .input-group.date').datetimepicker({
      format: 'L'
    });
  })
});

// clear datetimepicker
$(document).on('click', ".datetimepicker-group span[data-action='clear']", function(e) {
  var target = $(e.target).closest("span[data-action='clear']").data('target');
  $(target).datetimepicker('clear');
});

document.addEventListener("turbolinks:load", () => {
  $(function () {
    $('form#review-fill').areYouSure( {'softPageUnloadEvent': 'turbolinks:before-visit', 'message': 'Your changes to the review checkpoints have not been saved yet.  If you want to save them, choose Cancel, and click the Update button at the bottom of the checkpoint area.'});
  });
});

$.fn.select2.defaults.set('theme', 'bootstrap');
document.addEventListener("turbolinks:load", () => {
  $(function () {
    $('.select2').select2();
  });
});

function toggleAccess(e, accessLevel) {
  var me = $(e.target);
  let access = $(me).closest('.form-check').find("input[type='hidden']");
  let span = $(me).closest('.form-check').find("span.access");

  // if accessLevel is null then the checkbox was checked
  // otherwise the level was clicked so toggle it to the next
  if (accessLevel == null) {
    if (me.prop('checked')) {
      // set the access to 'Editable'
      $(access).val('Editable');
      $(span).text('Editable');
    } else {
      // set the access to 'Hidden'
      $(access).val('Hidden');
      $(span).text('Hidden');
    }
  } else {
    if ($(access).val() == 'Editable') {
      $(access).val('ReadOnly');
      $(span).text('ReadOnly');
    } else if ($(access).val() == 'ReadOnly') {
      $(access).val('Editable');
      $(span).text('Editable');
    }
  }
}

console.debug('wiring up checkpoints status access');
$(document).on('change', '.checkpoints-statuses input[type=checkbox]', function (e) {
  toggleAccess(e, null);
});
$(document).on('click', '.checkpoints-statuses span.access', function (e) {
  let fromAccess = $(e).text();
  toggleAccess(e, fromAccess);
});

document.addEventListener("turbolinks:load", () => {
  $(function () {
    $('.ujs-survey-flags').each(function (_i, el) {
      let id = $(el).data('id');
      $.get('/surveys/' + id + '/flags', function (data) {
        $(".ujs-survey-flags[data-id='" + id + "'").replaceWith(data);
      });
    });
  })
});



$(document).on('click', '.ujs-fill-goto-page', function (e) {
  e.preventDefault();
  $('form#fill').find('input[name="fill[goto]"]').val($(this).data('page'));
  $('form#fill').submit();
});

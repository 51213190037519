
// display the claim (or unclaim) info
$(document).on('ajax:success', 'a[data-action="claim"]', function (e) {
  var html = e.detail[0];
  $(e.target).closest('.claim').html(html.body);
});

// toggle the locked status of the questions on the survey in the review page
$(document).on('click', '.surveys-review .lock-status', function (e) {
  var container = $(e.target).closest('.lock-status');
  var questionId = $(e.target).closest('.lock-status').data('id');
  var surveyId = $(e.target).closest('.survey').data('id');

  $(container).html('<span class="spinner-border spinner-border-sm" role="status"></span>');

  $.get('/surveys/' + surveyId + '/togglelock/' + questionId, function (data) {
    $(container).replaceWith(data);
  });
});

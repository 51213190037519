// display the new comment form
$(document).on('ajax:success', 'a[data-action="new-comment"]', function (e) {
  // console.debug('got response new comment button');
  var html = e.detail[0];
  $('#commentsModal').find('.modal-body').html(html.body);
  // tell it where it needs to add the new comment
  $('#commentsModal').find('.modal-body').data('source', e.target);
  $('#commentsModal').modal('show');
});


// display the new comment where it belongs
$(document).on('ajax:success', 'form[data-remote]', function (e) {
  var html = e.detail[0];
  if ($(html).text() != "") {
    $(e.target).closest('.comments-container').find('li.no-comments').remove();
  }
  $('#commentsModal').modal('hide');
  // add the new comment where it belongs
  var source = $('#commentsModal').find('.modal-body').data('source');
  $(source).closest('.comments-container').find('ul.comments').prepend(html.body);
});

document.addEventListener("turbolinks:load", function() {    
  var pinned = $('li.pinned'); 
  pinned.prependTo(pinned.parent())
});

// set the focus on the message field when the comment popup appears
$(document).on('shown.bs.modal', '#commentsModal', function () {
  $('#commentsModal').find('.modal-body').find('textarea').trigger('focus');
})